angular.module('controllers').controller "DashboardTableCtrl",
    ($scope, $filter, $dialogs, $route, $location, CurrentUser, IcnIdentity, urlBuilder) ->
      $scope.orderByField = "name"
      $scope.isRia = IcnIdentity.user.isRia
      $scope.tableTitle = if $scope.isRia then 'Private Access Fund Client Management' else 'Investments'

      $scope.orderBy = (field) ->
        $scope.orderByField = field
        $scope.reverseSort = !$scope.reverseSort

      $scope.goToClientManagement = (fund_id, event) ->
        event.preventDefault()
        if !$scope.isRia && IcnIdentity.isIndividualInvestor()
          $location.url urlBuilder.reactUrl("/investment_management/#{fund_id}")
        else
          $location.url urlBuilder.reactUrl("/client_management/#{fund_id}")
